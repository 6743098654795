<template>
  <div class="main-body">
    <div class="container">
      <div class="left">
        <div class="content">
          <div class="heading">
            <div class="day"></div>
            <h4 class="office">Office</h4>
            <h4 class="gate">Gate</h4>
          </div>
        </div>
        <div class="content">
          <div class="row">
            <div class="day">MON-FRI</div>
            <div class="office-time">9am-6pm</div>
            <div class="gate-time">5am-9pm</div>
          </div>
          <div class="row">
            <div class="day">SAT</div>
            <div class="office-time">9am-9pm</div>
            <div class="gate-time">5am-9pm</div>
          </div>
          <div class="row">
            <div class="day">SUN</div>
            <div class="office-time">10am-1pm</div>
            <div class="gate-time">5am-9pm</div>
          </div>
        </div>
      </div>
      <div class="right" :style="{ background: slideData.background_color }">
        <div
          v-if="slideData.include_copy"
          class="title"
          v-html="slideData.slide_text"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hoursClosed',
  props: {
    slideData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.main-body {
  width: 100%;
  padding: auto;
}
.container {
  border: 2px solid black;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: end;
}
.left {
  width: 50%;
  background: rgba(2, 2, 2, 0.5);
  height: 50%;
  padding: 90px 90px;
  color: white;
}
.right {
  width: 50%;
  height: 50%;
  padding: 20px 50px;
  /* background: rgba(23, 236, 31, 0.7); */
  color: white;
}
.heading {
  /* display: flex;
  flex-direction: row;
  font-size: 40px;
  padding-top: 50px; */
  display: flex;
  flex-direction: coloumn;
  margin: 20px 0px;
  text-align: left;
  font-size: 60px;

  /* padding-right: 250px; */
}
.spacer {
  width: 30%;
}
.office {
  width: 35%;
  font-size: 65px;
  /* padding-right: 300px; */
}
.gate {
  width: 35%;
  font-size: 65px;
  /* padding-right: 200px; */
}
.content {
  font-size: 60px;
  margin: 5%;
  /* font-weight: bold; */
}
.row {
  display: flex;
  flex-direction: coloumn;
  border-bottom: 1px solid white;
  margin: 43px 0px;
  text-align: left;
}
.day {
  width: 30%;
  font-size: 48px;
}
.office-time {
  width: 35%;
  font-size: 45px;
}
.gate-time {
  width: 35%;
  font-size: 45px;
}
.title {
  padding-top: 5%;
  padding-left: 5%;
  text-align: left;
  font-size: 70px;
  width: 90%;
  /* font-size: 40px;
  font-weight: 700; */
}
</style>
