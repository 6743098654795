<template>
  <div>
    <div
      class="local-wrapper"
      :style="{ background: slideData.background_color }"
    >
      <div
        v-if="slideData.include_copy"
        class="local-description-box"
        v-html="slideData.slide_text"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'local_4',
  props: {
    slideData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.local-wrapper {
  position: fixed;
  height: 50%;
  color: white;
  bottom: 0;
  width: 50%;
}
.local-description-box {
  color: white;
  /* font-weight: bold; */
  text-align: left;
  padding-left: 164px;
  padding-top: 110px;
  font-size: 70px;
  width: 90%;
}
</style>
