<template>
  <div class="main-body">
    <div class="container">
      <div class="left">
        <div class="google-total-reviews">
          <div class="rating-row">
            <div class="google-icon">
              <img width="75" src="@/assets/google.svg" />
            </div>
            <div class="google-rating-container">
              <span class="google-rating">
                {{ getGoogleReviewOverallRating }}
              </span>
              <span class="google-stars">
                <span>
                  <span
                    v-for="(star, index) in ~~getGoogleReviewOverallRating"
                    :key="index"
                    class="rt_span"
                  >
                    <span class="full-rating-star">
                      <span class="icon-grey-star active"></span>
                    </span>
                  </span>
                  <span
                    v-for="(star, index) in 5 - ~~getGoogleReviewOverallRating"
                    :key="index + ~~getGoogleReviewOverallRating"
                    class="rt_span"
                  >
                    <span
                      v-if="
                        getGoogleReviewOverallRating % 1 !== 0 && index === 0
                      "
                      class="icon-grey-star half-star"
                    >
                      <span class="icon-grey-star active"></span>
                    </span>
                    <span v-else class="grey-star">
                      <span class="icon-grey-star"></span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div class="rating-block-container">
            <div
              v-for="googleReview in getGoogleReviews"
              :key="googleReview.time"
              class="rating-block"
            >
              <div class="review-avatar">
                <img
                  width="125"
                  :src="'https://d2i6hs4yervu5x.cloudfront.net/website/misc/review-ghost.png'"
                />
              </div>
              <div class="review-content-container">
                <h2>
                  <a :href="googleReview.author_url" target="_blank">
                    {{ googleReview.author_name }}
                  </a>
                </h2>
                <div class="review-time">
                  {{ dayjs(parseInt(googleReview.time * 1000)).from(dayjs()) }}
                </div>
                <div class="review-content">{{ googleReview.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="spacer"></div>
        <div
          class="bg-color"
          :style="{ background: slideData.background_color }"
        >
          <div
            v-if="slideData.include_copy"
            class="content"
            v-html="slideData.slide_text"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dayjsMixin from '@/mixins/dayjsMixin'

export default {
  name: 'review',
  props: {
    slideData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  mixins: [dayjsMixin],
  data() {
    return {}
  },
  computed: {
    ...mapState({
      facilityReviews: (state) => state.facilityReviews,
    }),
    getRatingCondition() {
      const ratingMapping = {
        'five star': 5,
        'four star and above': 4,
        'three star and above': 3,
        'two star and above': 2,
      }
      return ratingMapping?.[this.slideData?.review_rating] ?? 0
    },
    getGoogleReviewOverallRating() {
      return this.facilityReviews.find(
        (facilityReview) => facilityReview.type === 'google'
      ).overall_rating
    },
    getGoogleReviews() {
      const googleReviewsObj = this.facilityReviews.find(
        (reviews) => reviews.type === 'google'
      )
      console.log(googleReviewsObj.reviews)
      if (
        this.slideData?.review_count === 'all' &&
        this.slideData?.review_rating !== 'all'
      )
        return googleReviewsObj.reviews.filter(
          (review) => Math.floor(review.rating) >= this.getRatingCondition
        )
      else if (this.slideData?.review_count === 'all') {
        return googleReviewsObj.reviews
      } else
        return googleReviewsObj?.reviews?.[0]
          ? [googleReviewsObj?.reviews?.[0]]
          : []
    },
  },
}
</script>

<style scoped>
.main-body {
  width: 100%;
  height: 100%;
  padding: auto;
}
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.left {
  width: 37.5%;
  background-color: white;
  padding: 40px;
  padding-bottom: 0px;
  height: 100%;
  box-shadow: 1px 1px 40px 2px rgb(0 0 0 / 50%);
}

.left .google-total-reviews {
  height: 100%;
  padding-bottom: 0px;
  padding: 20px;
  text-align: initial;
}

.left .google-total-reviews .rating-row {
  height: 8%;
  display: flex;
}

.left .google-total-reviews .rating-row .google-rating-container {
  margin-left: 10%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-size: 30px;
}

.google-rating-container .icon-grey-star:before {
  content: '\e818';
  margin-right: 0px;
  margin-left: 0px;
  width: auto;
  font-family: 'fontello';
}

.google-rating-container span.icon-grey-star.active {
  color: #e7711b;
}

.google-rating-container span.icon-grey-star.half-star {
  position: relative;
  display: inline-block;
}
.google-rating-container span.icon-grey-star {
  color: #ccc;
}

.google-total-reviews .rating-block {
  display: flex;
  margin-top: 25px;
}

.google-total-reviews .rating-block-container {
  overflow-y: auto;
  /* margin-top: 70px; */
  height: 93%;
}

.google-total-reviews .rating-block-container .review-content-container {
  color: #908e92;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  font-size: 13px;
}

.google-total-reviews .rating-block-container .review-content-container h2 a {
  color: #908e92;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
}

.google-total-reviews .rating-block-container .review-content-container h2 {
  margin: 0;
}

.google-total-reviews .rating-block-container::-webkit-scrollbar {
  display: none;
}

.google-total-reviews .rating-block .review-avatar img {
  border-radius: 50%;
  width: 103px;
  height: 103px;
  border: 3px solid #dcdcdc;
}

.google-rating-container
  span.icon-grey-star.half-star
  span.icon-grey-star.active {
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
  width: 50%;
}
.left
  .google-total-reviews
  .rating-row
  .google-rating-container
  .google-rating {
  font-weight: 700;
  font-size: 60px;
  color: #8f8e92;
}

.left .google-total-reviews .google-stars {
  margin-left: 5%;
}

.right {
  width: 62.5%;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.spacer {
  height: 50%;
}
.content {
  text-align: left;
  padding-left: 129px;
  /* align-items: baseline; */
  /* padding: 90px; */
  padding-top: 5px;
  height: 50vh;
  color: white;
  font-size: 70px;
  padding-top: 114px;
  padding-right: 30px;
  /* height: 50vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center; */
}
.bg-color {
  height: 50vh;
}
</style>
<style>
.vueperslides__parallax-wrapper:before,
.vueperslides__parallax-wrapper:after {
  box-shadow: none !important;
}
</style>
