<template>
  <div class="container">
    <div class="gate-wraper">
      <div class="gate-item">
        <div v-if="getGateHours.length" class="office-hour-wrapper">
          <div class="office-hour">
            <h2 class="office-hour-heading">Gate Hours</h2>
            <div class="gate-timing-wrapper" :class="`gate-hours-${scheduledHoursGate.length }`">
              <div
                v-for="(obj, index) in scheduledHoursGate"
                :key="index"
                class="gate-timing"
              >
                <span>{{ obj.value }}</span>
                <span class="office-hour-time">
                  {{ obj.label }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
      <div class="hour" :style="{ background: slideData.background_color }">
        <div class="office-hour-wrapper">
          <div class="office-hour">
            <h2 class="office-hour-heading">Office Hours</h2>
            <div class="office-timing-wrapper" :class="`office-hours-${scheduledHoursGate.length }`">
              <div
                v-for="(obj, index) in scheduledHoursOffice"
                :key="index"
                class="office-timing"
              >
                <span>{{ obj.value }}</span>
                <span class="office-hour-time">{{ obj.label }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="text-content-wrapper">
          <div
            v-if="slideData.include_copy"
            class="text-content"
            v-html="slideData.slide_text"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { compressScheduledHours } from '@/utils/hours'
export default {
  name: 'hoursOpen_4',
  props: {
    slideData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      facilityHours: (state) => state.facilityHours,
    }),
    getOfficeHours() {
      const officeHoursObj = this.facilityHours.hours.find(
        (hours) => hours.type === 'office'
      )
      return officeHoursObj.hours.map((hour) => {
        const { appointment_only, close_time, day, open_time } = hour
        return {
          '24_hours': hour.is_always_open,
          appointment_only,
          close_time,
          day,
          open_time,
        }
      })
    },
    getGateHours() {
      const gateHoursObj = this.facilityHours.hours.find(
        (hours) => hours.type === 'gate'
      )
      // return gateHoursObj.hours
      return gateHoursObj.hours.map((hour) => {
        const { appointment_only, close_time, day, open_time } = hour
        return {
          '24_hours': hour.is_always_open,
          appointment_only,
          close_time,
          day,
          open_time,
        }
      })
    },
    sortedOfficeHours() {
      const map = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      }
      const officeHours = this.getOfficeHours
      officeHours.sort((a, b) => {
        return map[a.day] - map[b.day]
      })
      return officeHours
    },

    sortedGateHours() {
      const map = {
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
        sunday: 7,
      }
      const gateHours = this.getGateHours
      gateHours.sort((a, b) => {
        return map[a.day] - map[b.day]
      })
      return gateHours
    },
    scheduledHoursGate() {
      let compressedGateSchedule
      try {
        if (this.getGateHours)
          compressedGateSchedule = compressScheduledHours(this.sortedGateHours)
      } catch (err) {
        console.log('errr', err)
      }
      return compressedGateSchedule
    },
    scheduledHoursOffice() {
      let compressedSchedule
      try {
        if (this.getOfficeHours)
          compressedSchedule = compressScheduledHours(this.sortedOfficeHours)
      } catch (err) {
        console.log('errr', err)
      }
      return compressedSchedule
    },
  },
}
</script>

<style scoped>
.gate-wraper {
  height: 100vh;
}
.gate-item {
  width: 50%;
  height: 50%;
  background: rgba(2, 2, 2, 0.5);
}
.hour {
  display: flex;
  height: 50%;
}
.hour > * {
  flex-basis: 100%;
  flex-grow: 1;
}
.text-content {
  color: white;
  text-align: left;
  font-size: 70px;
  width: 90%;
  margin-left: 5%;
  margin-top: 5%;
}

.office-hour-wrapper {
  color: white;
  display: flex;
  justify-content: center;
}
.office-hour {
  margin-top: 10%;
  width: 80%;
  height: 250px;
}

.office-hour-heading {
  text-align: left;
  font-size: 60px;
  color: white;
  font-weight: bold !important;
}

.office-timing {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 10px;
  /* border-bottom: 1px solid white; */
}
.office-timing:not(:last-child) {
  border-bottom: 1px solid white !important;
}
.gate-timing {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 10px;
}
.gate-timing:not(:last-child) {
  border-bottom: 1px solid white !important;
}
.office-hour-time {
  margin-left: 100px;
}

/* .hour {
  position: fixed;
  background:rgba(76, 175, 80, 0.5);
  height: 50%;
  color: white;
  bottom: 0;
  width: 100vw;
} */
/* .spacer {
    display: flex;
    justify-content: flex-start;
} */
/* .title {
  width: 100%;
} */
/* .gate{
  display: grid;  
  color: white;
  text-align: left;
  padding-left: 100px;
  width: 50%;
  padding: 20px 50px;
  position: fixed;
  top:0;
  background: rgba(2, 2, 2, 0.5);
  height: 553px;
} */
/* .slidetext {
  width: 100%;
  padding-top: 100px;
} */
/* .hour-title {
  font-weight: 700;
  color: white;
  margin-left: 70px;
  margin-top: 80px;
} */
 .gate-timing-wrapper {
   font-size: 42px;
   padding-top: 20px;
}
 .gate-timing-wrapper.gate-hours-3, .gate-timing-wrapper.gate-hours-2 {
   line-height: 1.6;
}
 .gate-timing-wrapper.gate-hours-7 {
   font-size: 20px !important;
}
 .gate-timing-wrapper.gate-hours-7  {
   padding-top: 10px !important;
}
 .gate-timing-wrapper.gate-hours-6 {
   font-size: 22px !important;
}
 .gate-timing-wrapper.gate-hours-6  {
   padding-top: 12px !important;
}
 .gate-timing-wrapper.gate-hours-5 {
   font-size: 27px !important;
}
 .gate-timing-wrapper.gate-hours-5  {
   padding-top: 14px !important;
}
 .gate-timing-wrapper.gate-hours-4 {
   font-size: 37px !important;
}
 .gate-timing-wrapper  {
   padding-top: 14px;
   -webkit-background-clip: padding-box;
   background-clip: padding-box;
}
 .office-timing-wrapper {
  font-size: 42px;
  margin-top: 10px;
}
 .office-timing-wrapper.office-hours-6 {
   font-size: 22px !important;
}
 .office-timing-wrapper.office-hours-6  {
   padding-top: 30px !important;
}
 .office-timing-wrapper.office-hours-7 {
   font-size: 35px !important;
}
 .office-timing-wrapper.office-hours-7  {
   padding-top: 10px !important;
}
 .office-timing-wrapper.office-hours-5 {
   font-size: 24px !important;
}
 .office-timing-wrapper.office-hours-5  {
   padding-top: 35px !important;
}
 .office-timing-wrapper.office-hours-4 {
   font-size: 30px !important;
}
 .office-timing-wrapper.office-hours-3, .office-timing-wrapper.office-hours-2 {
   line-height: 1.6;
}
 .office-timing-wrapper  {
   padding-top: 20px;
   -webkit-background-clip: padding-box;
   background-clip: padding-box;
}
</style>

<!-- <div class="item-1">
        <div class="title">
          <div class="hour-title" >
            <h1 class="spacer"></h1>
            <h1>Office Hours</h1>
          </div>
        </div>
        <div class="slidetext" v-html="slideData.slide_text"></div>
      </div> -->
